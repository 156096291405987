<template>
  <BaseSupplier :isEdit="true" />
</template>

<script>
import BaseSupplier from '../baseSupplier'
export default {
  components: {
    BaseSupplier
  }
}
</script>

<style></style>
